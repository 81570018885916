export const resources = {
    books: [
        // {
        //     category: "Steven Forrest",
        //     description: "I have not read all of Steven Forrest's books, but of the ones I have read these are the ones I would truly recommend. If you are new to astrology, I would especially recommend the first three books, starting with The Inner Sky.",
        //     works: [
        {
            title: "The Inner Sky",
            author: ["Steven Forrest"],
            description: "Astrological basics. Planets, houses and signs. Birth chart analysis."
        },
        {
            title: "The Changing Sky",
            author: ["Steven Forrest"],
            description: "Transits and progressions."
        },
        {
            title: "Skymates",
            author: ["Steven Forrest, Jodie Forrest"],
            description: "Relationship astrology, synastry."
        },
        {
            title: "Yesterday's Sky: Astrology and Reincarnation",
            author: ["Steven Forrest"],
            description: "A good introduction to the evidence for reincarnation with an evolutionary approach to chart analysis starting with the nodes and nodal rulers. Clearly demonstrates why the Sun-Moon-Ascendant approach to chart analysis is often insufficient."
        },
        //     ]
        // },
        // {
        //     category: "Jeffrey Wolf Green",
        //     description: "The founder of the Pluto paradigm presenting his methods and accompanying worldview.",
        //     works: [
        {
            title: "Pluto Volume I: The Evolutionary Journey of the Soul",
            author: ["Jeffrey Wolf Green"],
            description: "The foundational work that no one can escape. The book contains metaphysical discussions of the soul's journey, a demonstration of methodology and Pluto through the houses/signs."
        },
        {
            title: "Pluto Volume 2: The Soul's Evolution through Relationships",
            author: ["Jeffrey Wolf Green"],
            description: "Relationship astrology from an evolutionary perspective, with a focus on the archetypes of Mars and Venus."
        },
        {
            title: "Structure of the Soul",
            author: ["Jeffrey Wolf Green"],
            description: "This is a brief but in-depth presentation of some of Jeffrey Green's core teachings. One of the first Jeffrey Green books I would recommend after Pluto Volume 1!"
        },
        {
            title: "Essays on Evolutionary Astrology",
            author: ["Jeffrey Wolf Green"],
            description: "A wide variety of essays by Green, including the highly significant planetary method of chart analysis in chapter 10."
        },
        //     ]
        // },
        // {
        //     category: "Jeffrey Wolf Green School of Evolutionary Astrology",
        //     description: "Written by a variety of people who are affiliated with the official Jeffrey Wolf Green school.",
        //     works: [
        {
            title: "EA Glossary: Guiding Principles of Jeffrey Wolf Green Evolutionary Astrology",
            author: ["Linda Jonson (editor)"],
            description: "A very good resource for the EA student looking for clarification of key concepts in the Pluto school, including the astrological archetypes, methods for chart interpretation, special placements in a chart, discussions of soul, evolution, spirituality, matriarchy/patriarchy."
        },
        {
            title: "Measuring the Night, Volume 1",
            author: ["Jeffrey Wolf Green, Steven Forrest"],
            description: "The two Pluto brothers complement each other nicely in this transcribed course from the late 90's. The introductory chapter alone is great and I personally also very much like Steven Forrest's lecture on the astrological Sun. Volume 2 is probably great as well, I haven't read it yet though!"
        },
        // {
        //     title: "Measuring the Night, Volume 2",
        //     author: ["Jeffrey Wolf Green, Steven Forrest"],
        //     description: ""
        // },
        // {
        //     title: "Insights into Evolutionary Astrology",
        //     author: ["Rose Marcus (editor)"],
        //     description: "Quite a bit of recapitulation of some of Jeffrey Green's basic ideas together with a few more practical chart analyses."
        // },
        {
            title: "Understanding Karmic Complexes",
            author: ["Patricia Walsh"],
            description: "The author empirically shows the strong correlations that exist between the charts and past-life memories of dozens of her clients. One of the most important EA books beyond the works of Jeffrey Green."
        },
        //     ]
        // },
        // {
        //     category: "Michael de Baker",
        //     description: "These books presuppose some familiarity with the Pluto paradigm. They are both technically challenging books and therefore very good. Michael demonstrates his penchant for systematic, sequential chart analysis which brings with it much needed clarity that is lacking in most other chart analyses.",
        //     works: [
        {
            title: "Intercepted Signs: Encoded Messages from the Soul",
            author: ["Michael de Baker"],
            description: "This book contains no less than seven in-depth chart analyses. Especially fascinating are the analyses of Anne Frank and her probable reincarnation Barbro Karlén. And even if you are new to EA, you can probably follow along because of the EA Training Points included throughout the book. The approach is fairly 'orthodox' except for how the author deals with intercepted signs (and duplicated signs), where he diverges from the EA mainstream. Instead of cookbook descriptions, there is also an excellent appendix at the back of the book listing a multitude of possible expressions of astrological archetypes, from the deepest to the most superficial."
        },
        {
            title: "Chiron: Trauma Key",
            author: ["Michael de Baker"],
            description: "This is a seminal work in many ways. The author introduces his trauma helix, showing how the outer planets can reflect an underlying trauma in their own ways; the new response of the Moon is described as facilitator of the PPP rather than the Lunar north node; oh, and Pluto is not the Soul. The chart analyses include all planetary nodes and are therefore very dense, so it is not a book you get through in a few sittings."
        },
        //     ]
        // },
        // {
        //     category: "Mark Jones",
        //     description: "Mark Jones is one of the most well-known evolutionary astrologers and a psychosynthesis practitioner. <i>The Soul Speaks</i> and <I>Healing the Soul</i> together give a fairly good overview of the Pluto paradigm in action from a therapeutic perspective.",
        //     works: [
        {
            title: "The Soul Speaks",
            author: ["Mark Jones"],
            description: "A discussion of the therapeutic potential of astrology."
        },
        {
            title: "Healing the Soul",
            author: ["Mark Jones"],
            description: "More practical, technically oriented than its predecessor, with 'cookbook' descriptions of Pluto and the nodes through the signs and houses."
        },
        {
            title: "The Planetary Nodes and Collective Evolution",
            author: ["Mark Jones"],
            description: "A study of the movements of the nodes of the outer planets through history. An interesting for the historically inclined (such as myself), but might be too dense for some."
        },
        //     ]
        // },
        // {
        //     category: "Miscellaneous",
        //     description: "These books aren't exactly EA, but may be helpful nonethelesss!",
        //     works: [
        {
            title: "Aspects in Astrology",
            author: ["Sue Tompkins"],
            description: "An accessible overview of much contemporary (and historical) thinking around aspects. The approach is not evolutionary, but there is still much in here that you can use regardless of astrological orientation."
        },
        {
            title: "Cosmos and Psyche",
            author: ["Richard Tarnas"],
            description: "A very important work where the author describes, beyond reasonable doubt, the historical significance embedded in aspects between the outer planets."
        },
        {
            title: "The Astrology of Personality",
            author: ["Dane Rudhyar"],
            description: "This astrology classic from 1936 is recommended for those who want to understand where much modern astrology comes from, including EA. Jeffrey Green, Mark Jones and many others have been greatly influenced by Rudhyar. The Astrology of Personality is his foundational work - a dense, philosophical discussion of the role and place of astrology in the modern world. Some of his later works may be more approachable, though I wouldn't know because I haven't read them!"
        },
        {
            title: "Astrology, Karma & Transformation",
            author: ["Stephen Arroyo"],
            description: "Arroyo doesn't use the term 'Evolutionary Astrology' - but in my opinion, this is one of the foundational works of EA. The introduction alone introduces many ideas that are fundamental in EA, and I would be surprised if Jeffrey Green was not influenced to some degree by this work. Some of the more cookbook-like descriptions have not aged well, but all in all this is a major work that is also very spiritual in outlook, containing rich quotes from Dane Rudhyar, Carl Jung, and the American clairvoyant Edgar Cayce."
        },
        //     ]
        // },
    ],
    websitesAndVideos: [
        {
            title: "School of Evolutionary Astrology",
            link: "https://schoolofevolutionaryastrology.com/",
            description: "Website and message board for the official Jeffrey Green school. Be aware that the message board is not a discussion forum. The rules for what questions you can ask there are rather strict. This is also the place to order DVD's from Jeffrey Green's various courses and lectures."
        },
        {
            title: "Raising Vibrations",
            link: "https://www.youtube.com/c/RaisingVibrations",
            description: "Good introduction videos to Pluto through the houses."
        },
        {
            title: "EA School Online",
            link: "https://www.easchoolonline.com/courses/",
            description: "Michael de Baker's online EA school, which I very much recommend. 15 hours of Zoom class per module, plenty of video lessons, thorough chart analyses, a pedagogical approach - and for some reason, relatively cheap."
        },
        {
            title: "Pluto School",
            link: "https://www.plutoschool.com/",
            description: "Mark Jones' website and online EA school."
        },
        {
            title: "Soul Wise Astrology School",
            link: "https://soulwiseastrologyschool.com/",
            description: "An online EA school run by original Jeffrey Green students Rose Marcus, Patricia Walsh and Laura Nalbandian."
        },
        {
            title: "LUNA Astrology",
            link: "https://www.lunaastrology.com/",
            description: "My preferred astrological software, developed by Kevin deCapite. Kevin is also a member of the Sher Institute of Astrology and Metaphysics, which has its own evolutionary approach to astrology, some of which has been integrated into LUNA."
        },
        {
            title: "Sabian Symbols",
            link: "https://sabiansymbols.com/symbols/",
            description: "Lest we forget that astrology is an intuitive science, the Sabian symbols challenge us to forget about rationality for a while and treat each degree of the zodiac as its own archetype. A very revealing set of symbols in my opinion!"
        },
        {
            title: "Steven Forrest and Mark Jones Talk Astrology",
            link: "https://www.youtube.com/watch?v=mkbKory4ANw",
            description: "An interesting discussion between to experienced evolutionary astrologers showing their commonalities and differences!"
        },
    ],
}
