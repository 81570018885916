import * as React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { resources } from "../../data/resources"

const Resources = () => (
    <Layout>
        <Seo title="Evolutionary astrology" />
        <h1>Planetary Sequence</h1>
        <p>Below is ...</p>

        1. The outer planets (Uranus, Neptune, Pluto) all point to aspects of past-life troubles and trauma - though such memories aren't really "past" but very much "in the now" as far as these outer planets are concerned. Traditionally called transpersonal, they are more aptly described as multipersonal, as they can hold all kinds of memories, habits and dispositions that have been "downloaded" from other lives in other times that have been created by the same overarching soul.
        2. The aspects of trauma connected to these outer planets can be described as
        - mental trauma (Uranus): aspect of mental fear or shock which can be triggered by situations that feel similar to traumatic situations from other lifetimes.
        - spiritual trauma (Neptune): aspect of deep meaninglessness in connection with the mental trauma.
        - psychological trauma (Pluto): aspect of deep, psychological identification with the trauma.

        3. The aspects of trauma are summarily interpreted by Chiron, who brings it into the etheric realm of the subtle body. The position of Chiron can therefore also be felt as an etheric aspect of trauma, for instance "a tight feeling across the chest".

        4. The Chiron pain is translated by Saturn as a need to create a particular kind of "structure of consciousness", like an operating system, for the current life. This in turn colors how all other planets, from Jupiter to Mercury, are conditioned to operate.


    </Layout>
)

export default Resources